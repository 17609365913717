// Photo component
(function ($) {
    if (!window.__)
        window.__ = {};

    window.__.photo = function () {
        $('[data-toggle=photo][data-remove]')
            .remove('[data-toggle=remove]')
            .append('<button class="btn btn-sm btn-danger" data-toggle="remove"><i class="fa fa-trash"></i></button>')
            ;

        $('[data-toggle=photo][data-download]').each(function (ev) {
            var e = $(this),
                url = e.attr('data-download')
                ;

            e.remove('[data-toggle=download]');

            if (url) e.append('<a class="btn btn-sm btn-primary" href="' + url + '" target="_blank" data-toggle="download" onclick="event.stopPropagation();"><i class="fa fa-download"></i></a>');
        });

        $('[data-toggle=photo][data-remote]:not([data-gallery])').attr('data-gallery', 'auto-grouping');
    };

    $(function () {
        var d = $(document);

        window.__.photo();

        d
            .on('click', '[data-toggle=photo][data-remote]', function () {
                $(this).ekkoLightbox({ alwaysShowClose: true, showArrows: true });
            })
            .on('click', '[data-toggle=photo] [data-toggle=remove]', function (ev) {
                ev.stopPropagation();
                ev.preventDefault();

                var e = $(this),
                    parent = e.closest('[data-remove]'),
                    url = parent.attr('data-remove')
                    ;

                e.prop('disabled', true);

                $.ajax({
                    method: 'DELETE',
                    url: url
                })
                    .done(function (data, textStatus, jqXHT) {
                        d.trigger('photo.removed', [url]);
                    })
                    .always(function () {
                        e.prop('disabled', false);
                    });
            })
            .on('photo.removed', function (ev, url) {
                $('[data-remove="' + url + '"]').remove();
            })
            ;
    });
})(jQuery);
